<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp" :pa-3="$vuetify.breakpoint.xsOnly">
      <v-flex xs12 max-width-1400 full-width ma-auto px-2 mb-5>
        <h1
          class="primary--text graphik-bold mb-2 mt-0"
          :class="{'font-35': $vuetify.breakpoint.smAndUp, 'font-25': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('faq_title') }}</h1>
        <p
          class="dark-grey--text graphik-light mb-5"
          :class="{'font-20': $vuetify.breakpoint.smAndUp, 'font-15': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('faq_description') }}</p>

        <v-container grid-list-xl fluid ma-0 pa-0>
          <v-layout row wrap>
            <template v-if="$vuetify.breakpoint.xsOnly">
              <v-flex xs6 class="py-0" v-for="(section, index) in sections" :key="index">
                <v-card color="light-background" flat tile>
                  <v-card-text class="pa-0">
                    <v-btn 
                      color="primary"
                      depressed dark block
                      :loading="g_isLoading"
                      class="normalcase graphik-medium my-2 border-radius-5"
                      @click="selected = section"
                    >{{ section.title }}</v-btn>
                  </v-card-text>
                </v-card>
              </v-flex>
            </template>

            <v-flex xs12 sm8>
              <v-card color="light-background" flat tile>
                <v-card-title class="align-end" :class="{'px-0': $vuetify.breakpoint.xsOnly}">
                  <h1 class="primary--text graphik-bold font-25 ma-0 line-height-25">{{ selected.title }}</h1>

                  <v-img
                    v-if="icon"
                    max-width="100"
                    position="bottom center"
                    class="ml-3"
                    :src="icon"
                    :lazy-src="icon"
                  ></v-img>
                </v-card-title>

                <v-card-text :class="{'px-0': $vuetify.breakpoint.xsOnly}">
                  <v-layout row wrap v-for="(row, index) in selected.questions" :key="index">
                    <v-flex xs12>
                      <p class="primary--text graphik-medium font-20">{{ row.question }}</p>

                      <p class="dark-grey--text graphik-light font-15 mb-4 faq-answer">{{ row.answer }}</p>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12 sm4 v-if="$vuetify.breakpoint.smAndUp">
              <v-card color="light-background" flat tile>
                <v-card-text>
                  <h1 class="primary--text graphik-bold font-25 mb-2 mt-0">{{ $ml.get('faq_sections') }}</h1>

                  <v-btn 
                    v-for="(section, index) in sections" :key="index"
                    color="primary"
                    depressed dark block
                    :loading="g_isLoading"
                    class="normalcase graphik-medium my-3 border-radius-5"
                    @click="selected = section"
                  >{{ section.title }}</v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'FAQ',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_FAQ,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_FAQ
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_FAQ
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_FAQ
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_FAQ
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_FAQ
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/preguntasfrecuentes'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/preguntasfrecuentes'
      }
    ]
  },
  data () {
    return {
      selected: {
        title: '',
        questions: [],
        icon: ''
      },
      sections: {
        about: {
          title: this.$ml.get('faq_title_about'),
          questions: [
            {
              question: this.$ml.get('faq_about_question_1'),
              answer: this.$ml.get('faq_about_answer_1')
            },
            {
              question: this.$ml.get('faq_about_question_2'),
              answer: this.$ml.get('faq_about_answer_2')
            },
            {
              question: this.$ml.get('faq_about_question_3'),
              answer: this.$ml.get('faq_about_answer_3')
            },
            {
              question: this.$ml.get('faq_about_question_4'),
              answer: this.$ml.get('faq_about_answer_4')
            },
            {
              question: this.$ml.get('faq_about_question_5'),
              answer: this.$ml.get('faq_about_answer_5')
            },
            {
              question: this.$ml.get('faq_about_question_6'),
              answer: this.$ml.get('faq_about_answer_6')
            }
          ],
          icon: 'question'
        },
        policies: {
          title: this.$ml.get('faq_title_policies'),
          questions: [
            {
              question: this.$ml.get('faq_policies_question_1'),
              answer: this.$ml.get('faq_policies_answer_1')
            },
            {
              question: this.$ml.get('faq_policies_question_2'),
              answer: this.$ml.get('faq_policies_answer_2')
            }
          ],
          icon: 'policies'
        },
        car: {
          title: this.$ml.get('faq_title_car'),
          questions: [
            {
              question: this.$ml.get('faq_car_question_1'),
              answer: this.$ml.get('faq_car_answer_1')
            },
            {
              question: this.$ml.get('faq_car_question_2'),
              answer: this.$ml.get('faq_car_answer_2')
            }
          ],
          icon: 'car'
        },
        house: {
          title: this.$ml.get('faq_title_house'),
          questions: [
            {
              question: this.$ml.get('faq_house_question_1'),
              answer: this.$ml.get('faq_house_answer_1')
            }
          ],
          icon: 'house'
        }
      }
    }
  },
  computed: {
    icon () {
      if (!this.selected.icon) return null
      return require('@/assets/img/icons/blue/' + this.selected.icon + '-no-margin.png')
    }
  },
  created () {
    this.selected = this.sections.about
  }
}
</script>

<style scoped>
  .faq-answer {
    white-space: pre-wrap;
  }
</style>
